.footer {
  background-color: $dark;
  padding: 63px 24px 7px;
  .newsletter-btn {
    cursor: pointer;
    margin-bottom: 5px;
    border: none;
    padding: 0;
    background-color: transparent;

    font-size: 20px;
    @include NGBold();
    letter-spacing: -0.4px;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
  }

  &-social {
    li {
      margin-top: 7px;

      &:first-child {
        margin-top: 0;
      }
    }

    a {
      font-size: 20px;
      @include NGBold();
      letter-spacing: -0.4px;
      color: $white;
      text-decoration: none;
    }

  }

  .logo {
    margin-top: 47px;
  }

  &-copyright{
    display: flex;
    gap: 50px;
    margin-top: 14px;

    span, a {
      @include NGBold();
      font-size: 14px;
      letter-spacing: -0.28px;
      color: $lightGray;
      text-decoration: none;
    }
  }
}

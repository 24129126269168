.tour {
    &-schedule {
      max-height: 520px;
      bottom: 65px;
    }
}
@media screen and (max-width: 580px) {
  .tour {
    .section_inner {
      margin-top: 8px;
      padding: 25px 20px 40px;
    }
    &-schedule {
      max-height: 455px;
      bottom: 35px;
    }
  }
}

@media screen and (max-width: 432px) {
  .tour {

    &.section {
      padding: 16px 10px 19px;
    }
    .section_inner {
      margin-top: 8px;
      padding: 14px 10px 17px;
    }

    .title {
      padding-left: 10px;
    }

    .subtitle {
      justify-content: space-between;
    }

    &-show {
      margin-top: 20px;
    }
    
    &-schedule {
      max-height: 325px;
      bottom: 35px;
    }
    .btn-more {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 375px) {
  .tour {
    &-schedule {
      max-height: 315px;
      bottom: 15px;
    }

    
    .subtitle {
      font-size: 16px;

      .btn-more {
        font-size: 14px;
        padding: 8px 18px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .tour {
    &-schedule {
      max-height: 250px;
      bottom: 15px;
    }
    .subtitle {
      .btn-more {
        font-size: 13px;
        padding: 7px 15px;
      }
    }
  }
}
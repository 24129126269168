.production {

  .title {
    max-width: 50%;
  }
  .section_inner {
    margin-top: 40px;
  }

  .description {
    margin-top: 23px;
    width: 50%;
  }

  &-show {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 25px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &_bg {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    .btn-more {
      position: absolute;
    }
  }
}
// global vars

// colors
$dark: #000000;
$white: #ffffff;
$red: #ff0000;
$darkRed: #df1313;
$blue: #2c46ed;
$darkBlue: #223194;
$deepBlue: #121b51;
$green: #02fb70;
$darkGreen: #33bd70;
$grayedGreen: #2b8d56;
$gray: #e4e3e3;
$darkGray: #474646;
$lightGray: #5e5e5e;


// breakpoints
$desktop: 100%;
$tablet: 1080;
$mobile: 767;

$desktop-bp: $desktop+'px';
$tablet-bp: $tablet+'px';
$mobile-bp: $mobile+'px';

.section {
  padding: 65px 25px 35px;
  position: relative;
  top: -4px;

  &_inner {
    padding: 25px 40px 40px;
  }

  .title {
    font-size: clamp(36px, 6vw, 100px);
    @include NGEBold();
    line-height: 1.02;
    letter-spacing: -2px;
    color: $white;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: clamp(18px, 2.1vw, 34px);
    @include NGEBold();
    letter-spacing: -0.36px;
    color: $white;
  }

  .description {
    p {
      font-size: clamp(18px, 1.1vw, 20px);
      @include NGBold();
      line-height: 1.3;
      letter-spacing: -0.36px;
      color: $white;

      &:nth-child(n+2) {
        margin-top: 35px;
      }
    }
  }

  &.lol-network {
    display: none;
  }
}

body {
  overscroll-behavior-y: none;
}

.btn-more {
  display: block;
  padding: 11px 29px;
  font-size: 16px;
  @include NGRegular();
  color: $white;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: -0.32px;
  border-radius: 27px;
  width: fit-content;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
}

.app-container {
  position: relative;
}

.logotype {
  padding: 15px 20px 25px;
  background-color: $white;
}
.timetable {
  &-body {

    &-cell {
      padding: 15px 30px 15px 0;
      line-height: 1.2;
      font-size: 17px;

      &:nth-child(1) {
        width: 70px;
        padding-left: 25px;
        padding-right: 0;
      }
      &:nth-child(2) {
        padding: 15px 45px;
      }
      &:nth-child(4) {
        padding-right: 25px;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .timetable {
    &-body {

      &-cell {
        padding: 15px 30px 15px 0;
        line-height: 1.2;
        font-size: 16px;

        &:nth-child(1) {
          padding-left: 15px;
          width: 60px;
        }
        &:nth-child(2) {
          .hallName {
            font-size: clamp(14px, 1.4vw, 14px);
            @include NGRegular();
            line-height: 1.3;
            letter-spacing: -0.32px;
            color: $white;  
          }        
        }
        &:nth-child(4) {
          padding-right: 15px;
        }
      }
    }
  }
}
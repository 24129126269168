.timetable {
  width: 100%;
  display: table;

  &-body {
    display: table-row-group;

    &-cell {
      display: table-cell;
      vertical-align: middle;
      padding: 18px 70px 18px 0;
      font-size: clamp(14px, 1.6vw, 18px);
      @include NGRegular();
      line-height: 1.3;
      letter-spacing: -0.32px;
      color: $white;
      border-bottom: 1px solid #979797;

      &:nth-child(2) {
        font-size: clamp(14px, 1.9vw, 24px);
        @include NGEBold();
        padding-right: 65px;
      }

      &:nth-child(4) {
        padding-right: 45px;
      }

      a {
        font-size: clamp(14px, 1.6vw, 18px);
        @include NGRegular();
        letter-spacing: -0.32px;
        color: $white;
        text-decoration: none;
      }
    }
  }

  &-row {
    display: table-row;

    &:last-child {
      .timetable-body-cell {
        border-bottom: none;
      }
    }
  }

  .timetable-row {
    &.hide {
      display: none;
    }
  }
}

@media screen and (max-width: 1280px) {

  .timetable {
    &-body {
      &-cell {
        
        &:nth-child(1) {
          padding-left: 25px;
          padding-right: 75px;
        }
        &:nth-child(4) {
          padding-right: 25px;
        }

      }
    }
  }

}
.lol-network {

  .title {
    text-align: right;
    padding-right: 41px;
    color: $dark;
  }

  .subtitle {
    color: $dark;
  }

  .section_inner {
    margin-top: 67px;
  }

  &-show {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    // gap: 32px;

    &_pic {
      flex: 65%;
      width: 100%;
      height: 100%;
    }

    &_more {
      flex: 40%;
      margin-left: 32px;

      .btn-more {
        margin-top: 40px;
      }
    }
  }
  
  .description {
    p {
      color: $dark;
    }
  }
}

@media screen and (max-width: 1180px) {

  .lol-network {
    &-show {
      flex-direction: column;
      &_more {
        margin-left: 0px;
        margin-top: 32px;
      }
    }
  }
}
.production {
  
  .section_inner {
    display: flex;
    flex-direction: column;
    padding: 40px;

    @media screen and (max-width: 414px) {
      margin-top: 22px;
      padding: 14px 14px 35px;
    }
  }

  .title {
    @media screen and (max-width: 414px) {
      max-width: 100%;
    }
  }

  .subtitle {
    margin-top: 21px;
    order: 2;
  }

  .description {
    width: 100%;
    order: 3;
  }

  &-show {
    margin-top: 0;
    order: 1;
  }
}


@media screen and (max-width: 414px) {
  .production {
  
    &.section {
      padding: 31px 22px 47px;
    }
  
    .section_inner {
      margin-top: 22px;
      padding: 22px 17px 35px;
    }

    .title {
      max-width: 100%;
    }

  }
}
.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 30;
  width: 800px;
  height: 770px;
  background-color: #fff;
}

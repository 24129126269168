.network {

}

@media screen and (max-width: 414px) {
  .network {
    
    .title {
      max-width: 100%;
      padding-right: 0;
    }

    &.section {
      padding: 31px 22px 44px;
    }

    .section_inner {
      margin-top: 35px;
      padding: 23px 14px 48px;
    }

    &-show {
      margin-top: 16px;
      &_more {
        margin-top: 26px;
      }
    }

    .description {
      p {
        &:nth-child(n+2) {
          margin-top: 30px;
        }
      }
    }

    .btn-more {
      margin-top: 35px;
    }
  }
}
.hero {
  position: relative;
  margin-top: 180px;

  &_pic {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

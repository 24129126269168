.venture {

}

@media screen and (max-width: 414px) {
  .venture {
    .title {
      max-width: 100%;
      padding-right: 0;
    }

    .section_inner {
      margin-top: 22px;
    }

    &-show {
      margin-top: 18px;
      &_more {
        margin-top: 21px;
      }
    }
  }
}
.business {

}

@media screen and (max-width: 414px) {
  .business {

    &.section {
      padding: 23px 10px 21px;
    }
    
    .section_inner {
      margin-top: 10px;
      padding: 22px 10px 31px;
    }

    .title {
      padding-left: 10px;
    }

    &-tabs {
      ul{
        gap: 24px;
        
        .tab-pic {
          margin-top: 13px;
        }
        li {
          padding: 15px 18px 27px;
        }
        h6 {
          font-size: 26px;
          letter-spacing: -0.52px;
        }
      }
    }
  }
}
@font-face {
    font-family: 'NeurialGrotesk-Light';
    src: url('../../assets/fonts/NeurialGrotesk-Light.eot');
    src: url('../../assets/fonts/NeurialGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/NeurialGrotesk-Light.woff2') format('woff2'),
        url('../../assets/fonts/NeurialGrotesk-Light.woff') format('woff'),
        url('../../assets/fonts/NeurialGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NeurialGrotesk-Regular';
    src: url('../../assets/fonts/NeurialGrotesk-Regular.eot');
    src: url('../../assets/fonts/NeurialGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/NeurialGrotesk-Regular.woff2') format('woff2'),
        url('../../assets/fonts/NeurialGrotesk-Regular.woff') format('woff'),
        url('../../assets/fonts/NeurialGrotesk-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NeurialGrotesk-Medium';
    src: url('../../assets/fonts/NeurialGrotesk-Medium.eot');
    src: url('../../assets/fonts/NeurialGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/NeurialGrotesk-Medium.woff2') format('woff2'),
        url('../../assets/fonts/NeurialGrotesk-Medium.woff') format('woff'),
        url('../../assets/fonts/NeurialGrotesk-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'NeurialGrotesk-Bold';
    src: url('../../assets/fonts/NeurialGrotesk-Bold.eot');
    src: url('../../assets/fonts/NeurialGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/NeurialGrotesk-Bold.woff2') format('woff2'),
        url('../../assets/fonts/NeurialGrotesk-Bold.woff') format('woff'),
        url('../../assets/fonts/NeurialGrotesk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NeurialGrotesk-Extrabold';
    src: url('../../assets/fonts/NeurialGrotesk-Extrabold.eot');
    src: url('../../assets/fonts/NeurialGrotesk-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/NeurialGrotesk-Extrabold.woff2') format('woff2'),
        url('../../assets/fonts/NeurialGrotesk-Extrabold.woff') format('woff'),
        url('../../assets/fonts/NeurialGrotesk-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@mixin font-smoothing() {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

// @include NGLight();
@mixin NGLight() {
    font-family: 'NeurialGrotesk-Light';
    font-style: normal;
    @include font-smoothing();
}

// @include NGRegular();
@mixin NGRegular() {
    font-family: 'NeurialGrotesk-Regular';
    font-style: normal;
    @include font-smoothing();
}

// @include NGMedium();
@mixin NGMedium() {
    font-family: 'NeurialGrotesk-Medium';
    font-style: normal;
    @include font-smoothing();
}

// @include NGBold();
@mixin NGBold() {
    font-family: 'NeurialGrotesk-Bold';
    font-style: normal;
    @include font-smoothing();
}

// @include NGEBold();
@mixin NGEBold() {
    font-family: 'NeurialGrotesk-Extrabold';
    font-style: normal;
    @include font-smoothing();
}
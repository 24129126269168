.join-form {
  padding: 10px;
  padding-bottom: 50px;
  &_body {
    flex-direction: column;
    align-items: center;
    .image-wrapper{
      height: 250px;
      max-width: 340px;
      margin-top: 0;
      background-position: 90% 0%;
    }
  }
  &_btn {
    top: 30px;
    right: 35px;
    color: #ffffff;
  }
}

.wrap-player {
  position: relative;
  width: 100%;
  background-color: $white;
  overflow: hidden;
  z-index: 0;

  &_video {
    width: 100%;
    max-height: 100%;
  }
}

.media-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 4px);
  width: 100%;
  background-position: top left;
  background-size: 3px;
  z-index: 10;
}
.timetable {
  &-body {

    &-cell {
      padding-right: 0px;
      font-size: 16px;
      @include NGBold();

      &:nth-child(1) {
        width: 70px;
        padding-left: 20px;
        padding-right: 0;
      }

      &:nth-child(2) {
        padding: 8px 40px;
        font-size: 18px;
      }

      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        padding-right: 20px;
      }

      a {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .timetable {
    &-body {
      &-cell {
        font-size: 14px;

        &:nth-child(1) {
          width: 60px;
        }

        &:nth-child(2) {
          padding: 5px 50px;
          font-size: 14px;

          .hallName {
            font-size: clamp(12px, 1.4vw, 14px);
            @include NGRegular();
            line-height: 1.3;
            letter-spacing: -0.32px;
            color: $white;
          }          
        }

        a {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 498px) {
  .timetable {
    &-body {
      &-cell {
        &:nth-child(2) {
          padding: 5px 35px;
          font-size: 14px;        
        }
      }
    }
  }
}

@media screen and (max-width: 428px) {
  .timetable {
    &-body {
      &-cell {

        &:nth-child(1) {
          padding-left: 10px;
        }
        &:nth-child(4) {
          padding-right: 10px;
        }

        &:nth-child(2) {
          padding: 5px 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .timetable {
    &-body {
      &-cell {
        font-size: 13px;

        &:nth-child(1) {
          width: 55px;
        }

        a {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .timetable {
    &-body {
      &-cell {
        @include NGRegular();

        &:nth-child(1) {
          padding-left: 5px;
        }
        &:nth-child(4) {
          padding-right: 5px;
        }

        &:nth-child(2) {
          padding: 5px 15px;
          @include NGMedium();
        }
      }
    }
  }
}
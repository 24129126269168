
@media screen and (max-width: 414px) {
  .section {
    padding: 31px 22px 29px;

    &_inner {
      margin-top: 22px;
      padding: 14px 14px 35px;
    }

    
    .title {
      letter-spacing: -0.8px;
    }
  }
}
.header {
    top: 0;
    left: 0;
    background-color: #000;
    max-width: 1440px;
    right: 0;
    z-index: 100;

    &.fixed {
        position: fixed;
    }

    .menu {
        display: none;
    }

    .header_logo {
        position: relative;
        z-index: 100;
        padding: 10px 8px 3px;
    }
    .mobile-menu {
        display: block;
        position: relative;
        z-index: 100;
        margin-top: -2px;

        .menu-burger {
            background: #fff;
            font-size: 18px;
            padding-right: 6px;
            padding-top: 5px;
            padding-bottom: 3px;
            text-align: right;
            @include NGBold;
            font-weight: 800;
        }

        .menu-bg {
            display: none;
            z-index: 998;
            background-color: #000;
            padding: 0 2rem;
            position: fixed;
            height: 100%;
            width: 100%;
            transition: .6s ease-in-out;
            overflow-y: auto;

            @media screen and (max-width: 375px) {
              padding: 0 1rem;
            }

            ul.nav {
                font-size: 30px;
                @include NGEBold;
                line-height: normal;
                padding: calc(183px - 1rem) 0 200px;

                @media screen and (max-width: 414px) {
                  padding: calc(183px - 1rem) 0 100px;
                }

                @media screen and (max-width: 390px) {
                  padding: calc(126px - 1rem) 0 100px
                }

                @media screen and (max-width: 375px) {
                  padding: calc(150px - 1rem) 0 80px;
                }

                @media screen and (max-width: 320px) {
                  font-size: 24px;
                  padding: calc(110px - 1rem) 0 80px;
                }

                li {
                    margin-bottom: 5px;

                    a {
                        letter-spacing: -0.6px;
                        color: #fff;
                        text-decoration: none;

                        &:hover {
                            color: #f00;
                        }
                    }
                }
            }
            ul.social {
                li {
                    margin-top: 7px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                a {
                    font-size: 16px;
                    @include NGBold();
                    letter-spacing: -0.4px;
                    color: $white;
                    text-decoration: none;

                    @media screen and (max-width: 320px) {
                      font-size: 15px;
                    }
                }
            }
        }
    }

    @keyframes slideIn {
        0% {
            opacity: 0;
            transition: all .4s ease;
        }
        100% {
            opacity: 1;
            transition: all .4s ease;
        }
    }

    &.is-opened {
        animation: slideIn .4s;
        .header_logo {
            background-color: #000;
        }

        .mobile-menu {
            .menu-burger {
                background: #000;
                color: #fff;
            }
        }
        .menu-bg {
            display: block;
        }
    }
}

.join-form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
  &_body {
    width: 100%;
    display: flex;
    .image-wrapper {
      margin-top: 20px;
      max-height: 680px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: contain;
      image {
        width: 100%;
        height: 100%;
      }
    }
    .iframe {
      max-height: 700px;
      max-width: 560px;
      min-height: 700px;
      height: 100%;
      width: 100%;
    }
  }
  &_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #000;
    text-transform: uppercase;

    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
}

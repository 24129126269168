.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;

  &_logo {
    padding: 15px 20px 25px;
    background-color: $white;
  }

  .menu {
    padding: 34px 20px;
    display: flex;
    position: relative;
    z-index: 20;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      padding-top: 3px;

      a {
        font-size: 30px;
        @include NGBold;
        color: $white;
        letter-spacing: -0.6px;
        text-decoration: none;
        
        @media screen and (max-width: 1180px) {
          font-size: 18px;
        }
        
        @media screen and (max-width: 980px) {
          font-size: 16px;
        }
      }
    }
    &_logo {
      display: none;
      a {
        font-size: 50px;
        @include NGBold;
        letter-spacing: -1px;
        text-decoration: none;

        @media screen and (max-width: 1180px) {
          font-size: 24px;
        }
      }
    }
  }


  &.nav {
    .menu {
      opacity: 0;
    }
  }

  &.nav {
    &.scrolled {
      .menu {
        opacity: 1;
        background-color: #000;
        position: fixed;
        z-index: 1000;
        width: 100%;
        box-sizing: border-box;
        top: 0;
        animation-name: slideOut;
        animation-duration: 0.8s;
        animation-delay: 0.95s;
        animation-fill-mode: forwards;

        &.show {
          animation-name: slideIn;
          animation-duration: 0.8s;
          animation-delay: unset;
          animation-fill-mode: forwards;
        }

        &_logo {
          display: block;
          margin-right: 128px;
          @media screen and (max-width: 980px) {
            margin-right: 30px;
          }
          a {
            color: $white;
          }
        }

        ul {
          a {
            font-size: 24px;
            @include NGBold;
            color: $white;
            letter-spacing: -0.48px;
            text-decoration: none;
            
            @media screen and (max-width: 1180px) {
              font-size: 18px;
            }
            @media screen and (max-width: 980px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .mobile-menu {
    display: none;
  }
}

  @keyframes slideOut {
    0% {
      opacity: 0;
      top: 0;
    }

    10% {
      opacity: 0.1;
    }

    20% {
      opacity: 0.2;
    }

    30% {
      opacity: 0.3;
    }

    40% {
      opacity: 0.4;
    }

    50% {
      opacity: 0.5;
    }

    60% {
      opacity: 0.6;
    }

    // 70% {
    //   opacity: 70%;
    //   transform: translateY(-70%);
    // }

    // 80% {
    //   opacity: 80%;
    //   transform: translateY(-80%);
    // }

    // 90% {
    //   opacity: 90%;
    //   transform: translateY(-90%);
    // }

    100% {
      opacity: 1;
      top: -200px;
    }
  }

  @keyframes slideIn {
    0% {
      top: -100%;
      opacity: 0;
    }

    80% {
      opacity: 0.8;
    }

    100% {
      top: 0;
      opacity: 1;
    }
  }

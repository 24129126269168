.tour {
  &-show {
    &_logo {
      display: none;
    }
  }
  
  &-schedule {
    max-height: 380px;
    bottom: 25px;
    
    @media screen and (max-width: 768px) {
      max-height: 555px;
    }
  }
}
.business {

  .section_inner {
    margin-top: 32px;
  }

  &-tabs {
    margin-top: 35px;
  }
  
}

.business-tabs {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      gap: 35px;

      li {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 22px;
      }

      .tab-pic {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        &.events-none {
          pointer-events: none;
        }

        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }

        span {
          position: absolute;
          @include NGRegular();
          color: $white;
          font-size: 24px;
          letter-spacing: -0.48px;
        }
      }

      h6 {
        @include NGEBold();
        color: $white;
        font-size: 34px;
        letter-spacing: -0.68px;
      }
    }
}
.tour {
  
  .section_inner {
    margin-top: 20px;
  }

  .subtitle {
    display: flex;
    align-items: center;
  }

  &-show {
    position: relative;
    margin-top: 25px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &_bg {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &_logo {
      position: absolute;
      top: 47px;
      left: 42px;
      width: clamp(35px, 10vw, 185px);
      height: clamp(40px, 11.5vw, 211px);
    }
  }

  &-schedule {
    position: absolute;
    bottom: 75px;
    right: 0;
    max-height: 536px;
    overflow: auto;

    &::-webkit-scrollbar-track {
        background: black;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ff0000;
        border-radius: 20px;
        border: 3px solid black;
    }
  }

  .btn-more {
    margin-left: 20px;
  }
}


@media screen and (max-width: 1280px) {
  .tour {
    &-schedule {
      max-height: 510px;
      bottom: 35px;
    }
  }
}